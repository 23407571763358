import React from 'react'
import Layout from '../../components/layout/Layout'
import { SeriesPage } from '../../components/series/SeriesPage'

const JavaScriptmancySeries = () => {
  const name = 'javascriptmancy'
  const title = 'JavaScript-mancy'
  const subtitle = 'Mastering the Arcane Art of Writing Awesome JavaScript'
  const image = '/images/javascriptmancy-series-cover.jpg'
  const imageAlt =
    'A young wizard practices the fine wizardring arts with mixed success throwing fireballs at her master.'

  return (
    <Layout>
      <SeriesPage
        name={name}
        title={title}
        subtitle={subtitle}
        image={image}
        imageAlt={imageAlt}
      >
        <p>
          A magical and fun blend of JavaScript and Fantasy. Learn to write
          JavaScript in the company of orcs, wizards, elves and dragons.
        </p>
        <p>
          Writing code is one of my favorite past times and so is reading
          fantasy books. For this project I wanted to mix these two passions of
          mine and try to make something awesome out of it.
        </p>
        <p>
          In fantasy we usually have the idea of magic, usually very powerful,
          very obscure and only at the reach of a few dedicated individuals. I
          thought that drawing a parallel between magic and what we programmers
          do daily would be perfect. Because it is obscure to the untrained mind
          and requires a lot of work and study to get into, and because we have
          the power to create things out of nothing.
        </p>
      </SeriesPage>
    </Layout>
  )
}

export default JavaScriptmancySeries
